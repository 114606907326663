export interface ILandingPageLocationExist {
    lpLocationType: LandingPageLocationType;
}

export enum LandingPageLocationType {
    NOT_EXIST = 1,
    SUBCATEGORY = 2,
    CATEGORY = 3,
    CITY = 4,
}
