import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MemoryCache } from 'memory-cache-node';
import { Observable, map, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CACHE_DURATION } from './config/rest.model';
import { ILandingPageLocationExistRequest } from './models/landing-page-location-exist-request.model';
import { LandingPageLocationType } from './models/landing-page-location-exit.model';
import {
    ILandingPageLocationExistsCache,
    LandingPageLocationCacheMap,
} from './models/landing-page-location-exits-cache.model';
import { ILandingLocationPageGetRequest, LandingPageDto } from './models/landing-page.model';

const CACHE = new MemoryCache<string, object>(1, 10);

@Injectable({
    providedIn: 'root',
})
export class LandingLocationPageRestService {
    private readonly _httpClient = inject(HttpClient);

    private readonly _baseUrl = `${environment.portalApi}/LandingPageLocation`;

    public get(request: ILandingLocationPageGetRequest): Observable<LandingPageDto> {
        let params = new HttpParams();

        if (request.category) {
            params = params.append('category', request.category);
        }
        if (request.subcategory) {
            params = params.append('subcategory', request.subcategory);
        }

        return this._httpClient.get<LandingPageDto>(`${this._baseUrl}/${request.city}`, { params });
    }

    public exist(request: ILandingPageLocationExistRequest): Observable<LandingPageLocationType> {
        let params = new HttpParams();

        if (request.category) {
            params = params.append('category', request.category);
        }
        if (request.subcategory) {
            params = params.append('subcategory', request.subcategory);
        }
        if (request.location) {
            params = params.append('location', request.location);
        }

        return this._getExistsCacheMap().pipe(
            map((data) => {
                const _params = request;
                let key = this._getCacheKey(_params);
                let found = data[key];
                if (found) {
                    return found.searchMode;
                }

                _params.subcategory = 'null';
                key = this._getCacheKey(_params);
                found = data[key];
                if (found) {
                    return found.searchMode;
                }

                _params.category = 'null';
                key = this._getCacheKey(_params);
                found = data[key];
                if (found) {
                    return found.searchMode;
                }

                return LandingPageLocationType.NOT_EXIST;
            }),
        );
    }

    private _getCacheKey(request: ILandingPageLocationExistRequest): string {
        const separator = '::';
        return [request.location, request.category ?? 'null', request.subcategory ?? 'null'].join(separator);
    }

    private _getExistsCacheMap(): Observable<LandingPageLocationCacheMap> {
        if (CACHE.hasItem('data')) {
            return new Observable((observer) => {
                observer.next(CACHE.retrieveItemValue('data') as LandingPageLocationCacheMap);
                observer.complete();
            });
        }

        const path = `${environment.cacheBlobUrl}/landingpages.json`;

        return this._httpClient.get<ILandingPageLocationExistsCache[]>(path).pipe(
            catchError((err) => of([])),
            map((response) => {
                // Z response tworzę słownik, który będzie przechowywany w cache
                // Kluczem jest połączenie location, category i subcategory
                // Wartością jest cały obiekt zwrócony z API
                // W ten sposób będę mógł szybko sprawdzić czy dany obiekt istnieje w cache
                const dictionary: LandingPageLocationCacheMap = {};
                for (let index = 0; index < response.length; index++) {
                    const element = response[index];
                    const key = this._getCacheKey(element);
                    dictionary[key] = element;
                }

                CACHE.storeExpiringItem('data', dictionary, CACHE_DURATION);
                return CACHE.retrieveItemValue('data') as LandingPageLocationCacheMap;
            }),
        );
    }
}
